.font{
  font-family: 'DM Sans', sans-serif;
}
.black-header{
    background-color:#262626!important ;
}
.logo{
    height: 95px;
    width: auto;
    object-fit: scale-down!important;
}
.flag{
    width: 25px;
    height: 15px;
}
.subheader{
   background-color:#353535!important ;
}
._left_{
    width: 13%;
}
._center_{
    width: 77%;
}
._right_{
    width: 10%;
}
._left_interno_{
    width: 25%;
}
._center_interno_{
    width: 77%;
}
._right_interno_{
    width: 25%;
}
._center_interno_small_{
    width: 80%;
}
.logo_small{
    height: 65px;
    width: auto;
    object-fit: scale-down!important;
}
input{
    outline: none;
}
.tamano{
    font-size: 40px!important;
    font-family: 'DM Sans', sans-serif;
}
.tamano_small{
    font-size: 20px!important;
    font-family: 'DM Sans', sans-serif;
}
._contenedor_banner_{
    width: 100%!important;
    height: 400px!important;
    object-fit: cover!important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
._contenedor_banner_small{
    width: 100%!important;
    height: 300px!important;
    object-fit: cover!important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: black;
}

.texto-encima{
    height: 400px!important;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
}
/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;


  }

  .boton{
      background-color: #4B4B4B!important;
  }
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #ffffff;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #c2c2c2;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  .ver_mas{
      background-color: #5F8DB5;
  }
  .subtitulo{
    color: #4C4B4C
  }
  .parrafo{
    color: #4C4B4C
  }
  .footer{
      height: 470px;
  }
  .subtitulo-blue{
      color: #5F8DB5;
  }
  .know-background{
    background-color: #5F8DB5;
  }
  .subtitulo_white{
    color: white;
  }
  .WhatDo-background{
    background-color: black;
  }
  .whatDoImage{
    width: 80%;
  }
  .HowEmpresaSize{
    height: 500px;
  }

  .WhatDoSize{
    height: auto!important;
  }
  .PhilosophySize{
    height: auto!important;
  }
  .fondo{
      z-index: -1;
  }
