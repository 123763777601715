@tailwind base;
@tailwind components;
@tailwind utilities;
.sliderimg{
  width: 100%;
  height: 500px;
  object-fit: cover
}
html {
  scroll-behavior: smooth;
}
.ocultar{
  display: none;
}
.visible{
  background-color: "red";
}
.textarea {
  height: 10em;
}
